import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {IoIosArrowUp} from 'react-icons/io';
import {IoIosArrowDown} from 'react-icons/io';

import {
  prepareDateValueData,
  prepareTotalTrackerActiveMinutesData,
  prepareSleepDurationData,
  prepareRestingHeartRateData,
} from './FitbitPrepareDataForDisplay';
import Table from '../../table/Table';
import {DisplayDateValueChart} from '../../displayData/DisplayDateValueChart';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';
import FitbitParticipant1DayData from '../participant/FitbitParticipant1DayData';
import Modal from '../../modal/Modal';

import './DisplayRefinedData.css';

const DisplayRefinedData = (props) => {
  const {
    deviceName,
    subject,
    data,
    queryInfo,
    participantInfo,
    displayInfo = {style: '', showTable: true},
  } = props;
  const [participantData, setParticipantData] = useState(null);
  const [displayDataName, setDisplayDataName] = useState(null);
  const [displayTable, setDisplayTable] = useState(displayInfo.showTable);
  const [displayModal, setDisplayModal] = useState(false);
  const [participantModalData, setParticipantModalData] = useState(null);
  const [errors, setErrors] = useState(null);

  const isDateValueData = (src) => {
    switch (src) {
      case 'calories':
      case 'caloriesBMR':
      case 'steps':
      case 'distance':
      case 'floors':
      case 'elevation':
      case 'minutesSedentary':
      case 'minutesLightlyActive':
      case 'minutesFairlyActive':
      case 'minutesVeryActive':
      case 'activityCalories':
      case 'tracker-calories':
      case 'tracker-steps':
      case 'tracker-distance':
      case 'tracker-floors':
      case 'tracker-elevation':
      case 'tracker-minutesSedentary':
      case 'tracker-minutesLightlyActive':
      case 'tracker-minutesFairlyActive':
      case 'tracker-minutesVeryActive':
      case 'tracker-activityCalories':
      case 'bmi':
      case 'fat':
      case 'weight':
      case 'caloriesIn':
      case 'water':
        return true;
      default:
        return false;
    }
  };

  const isSingleRowClickable = (subject) => {
    switch (subject) {
      case 'Sleep Duration':
      case 'Total Tracker Active Minutes':
        return true;
      default:
        return false;
    }
  };

  const getResourceName = (queryInfo) => {
    switch (queryInfo.category) {
      case 'activities':
        return queryInfo.activityResourcePath;
      case 'body':
        return queryInfo.bodyResourcePath;
      case 'heart':
        return queryInfo.heartOptions;
      case 'sleep':
        return queryInfo.sleepOptions;
      case 'foods':
        return queryInfo.foodsOptions;
      default:
        return '';
    }
  };

  const getChartType = (category, resource) => {
    switch (category) {
      case 'activities':
        switch (resource) {
          case 'totalTrackerActiveMinutes':
            return 'BarChart';
          default:
            return 'LineChart';
        }
      case 'sleep':
        switch (resource) {
          case 'duration':
            return 'ComposedBarLineChart';
          default:
            return 'BarChart';
        }
      default:
        return 'LineChart';
    }
  };

  useEffect(() => {
    const refineData = (data, queryInfo) => {
      try {
        // If src exists, check if it is a date & value type and if yes display it in
        // chart(s) and a table. If this is a more complex data display it as a list
        const category = queryInfo.category;
        let resource = getResourceName(queryInfo);
        let result = [];
        if (resource) {
          if (isDateValueData(resource)) {
            // This is a flatten date & value style data with a single dept object
            result.subject = queryInfo.subject;
            result.chartType = getChartType(category, resource);
            result.data = data;
            result = prepareDateValueData(result);
            setParticipantData(result);
            setDisplayDataName('dateValueData');
          } else if (category === 'activities') {
            if (resource === 'totalTrackerActiveMinutes') {
              result.subject = queryInfo.subject;
              result.chartType = getChartType(category, resource);
              result.data = data;
              result = prepareTotalTrackerActiveMinutesData(result);
              setParticipantData(result);
              setDisplayDataName('dateValueData');
            }
          } else if (category === 'heart') {
            result.subject = queryInfo.subject;
            result.chartType = getChartType(category, resource);
            result.data = data;
            result = prepareRestingHeartRateData(result);
            setParticipantData(result);
            setDisplayDataName('dateValueData');
          } else if (category === 'sleep') {
            result.subject = queryInfo.subject;
            result.chartType = getChartType(category, resource);
            result.data = data;
            result = prepareSleepDurationData(result, displayInfo.style);
            setParticipantData(result);
            setDisplayDataName('dateValueData');
          } else if (category === 'foods') {
            result.subject = queryInfo.subject;
            result.chartType = getChartType(category, resource);
            result.data = data;
            result = prepareRestingHeartRateData(result);
            setParticipantData(result);
            setDisplayDataName('dateValueData');
          } else {
            throw new Error('Error: Could not prepare Fitbit data for display');
          }
        } else {
          throw new Error('Error: Could not prepare Fitbit data for display');
        }
      } catch (error) {
        setErrors('Error: Could not prepare Fitbit data for display');
        if (error && error.message) {
          console.error(error.message);
          // setErrors(error.message);
        }
        // else {
        //   setErrors('Error: Could not prepare Fitbit data for display');
        // }
      }
    };

    // Refine the data for display
    if (data && queryInfo) {
      refineData(data, queryInfo);
    }
  }, [data, queryInfo, displayInfo.style]);

  // Row in the table was clicked, display data for only this row
  const tableRowClicked = (row) => {
    // Check if rows in this table are clickable based on the subject
    if (isSingleRowClickable(participantData.subject)) {
      // If row is clickable display a single day data based on the date of the current row
      setDisplayModal(true);
      if (participantData && participantData.data) {
        let participantRowData = {...participantData};
        // Use the date from the selected row and get only the data for this date
        participantRowData.data = participantData.data.filter((obj) => {
          return obj.date === row.date;
        });
        setParticipantModalData(participantRowData);
      }
    }
  };

  const showMoreClicked = () => {
    setDisplayTable(!displayTable);
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  const displayData = () => {
    if (!participantData.displayStyle) {
      participantData.displayStyle = {};
    }
    participantData.displayStyle.stacked = null;
    participantData.displayStyle.height = 400;

    switch (displayDataName) {
      case 'dateValueData':
        return (
          <div>
            <h3 className="data-title">{queryInfo.subject}</h3>
            <DisplayDateValueChart
              content={participantData}
              displayTable={true}
              chartType={participantData.chartType}
            />
            <button onClick={showMoreClicked} className="show-more-btn">
              {displayTable ? (
                <IoIosArrowUp className="show-less" />
              ) : (
                <IoIosArrowDown className="show-more" />
              )}
              {displayTable ? 'Show Less' : 'Show More'}
            </button>

            <div
              className={
                isSingleRowClickable(queryInfo.subject)
                  ? 'singleRowSelection'
                  : ''
              }
            >
              {displayTable && (
                <Table
                  deviceName={deviceName}
                  tableName={subject}
                  data={participantData.data}
                  columns={participantData.columns}
                  dataCallback={tableRowClicked}
                  features={{
                    singleRowSelection: true,
                    multipleRowsSelection: false,
                    maxNumOfSelectedRows: null,
                    displayGlobalFilter: false,
                    exportDataToFile: true,
                  }}
                />
              )}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {errors && <DisplayErrors errors={errors} />}
      {participantData && displayData()}
      {displayModal && participantModalData && (
        <div className="participant-data-modal">
          <Modal title={''} closeModalCallback={closeModal}>
            <div className="">
              <FitbitParticipant1DayData
                participantData={participantModalData}
                participantInfo={participantInfo}
              />
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

DisplayRefinedData.propTypes = {
  deviceName: PropTypes.string,
  subject: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  queryInfo: PropTypes.object,
  participantInfo: PropTypes.object,
  displayInfo: PropTypes.object,
};

export {DisplayRefinedData};
