import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import ReactTooltip from 'react-tooltip';

import {useYupValidationResolver} from '../forms/YupValidationResolver';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';
import useApi from '../api/useApi';

import '../modal/ModalForm.css';
import './TrialUsersForm.css';

// Yup form validation rules
const validationSchema = yup.object({
  userEmail: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
});

function NewTrialUsersForm({
  trialInfo,
  getTrialUsersDataCallback,
  cancelTrialUsersEditCallback,
}) {
  const {trialId, trialName, trialNumber} = trialInfo;

  const resolver = useYupValidationResolver(validationSchema);
  const [regError, setRegError] = useState('');
  const [teamUsers, setTeamUsers] = useState([]);
  const [url, setUrl] = useState(null);

  // Make sure that we have the trial info before sending to the server
  useEffect(() => {
    if (trialId) {
      setUrl(`/teamUsers/${trialId}`);
    }
  }, [trialId]);

  // Retrieve all users from the database who belong to the current team
  // but are not part of the current trial yet.
  const {data: teamUsersData, loading, error: apiErrors} = useApi(url);

  useEffect(() => {
    if (teamUsersData) {
      setTeamUsers(teamUsersData);
    }
  }, [teamUsersData]);

  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
  } = useForm({mode: 'onSubmit', resolver});

  // Insert current values to form's fields
  useEffect(() => {
    reset({
      trialId: trialId,
      trialName: trialName,
      trialNumber: trialNumber,
    });
  }, [reset, trialId, trialName, trialNumber]);

  const onSubmit = (data) => {
    // Upon success return data to the parent component
    getTrialUsersDataCallback(data);
  };

  const clearRegErrorMessage = () => {
    setRegError('');
  };

  const handleCancelTrialUsersEdit = () => {
    cancelTrialUsersEditCallback();
  };

  return (
    <form className="modal-form trials-form" onSubmit={handleSubmit(onSubmit)}>
      {regError && <div className="form-error centered-text">{regError}</div>}
      {apiErrors && <div className="form-error centered-text">{apiErrors}</div>}
      {loading ? <div className="spinnerModal"></div> : ''}
      <fieldset>
        <p>Only items marked with (*) are required.</p>
        <input type="hidden" {...register(`trialId`)} />
        <div className="form-item">
          <div className="form-error" aria-live="polite">
            {errors.trialNumber?.message}
          </div>
          <div>
            <label>*Trial Number</label>
          </div>
          <div>
            <input
              disabled
              name="trialNumber"
              type="input"
              // autoComplete='off'
              {...register('trialNumber')}
              onFocus={clearRegErrorMessage}
              className={`form-input ${errors.trialNumber ? 'is-invalid' : ''}`}
            />
          </div>
        </div>
        <div className="form-item">
          <div>
            <label>*Trial Name</label>
          </div>
          <div className="form-error" aria-live="polite">
            {errors.trialName?.message}
          </div>
          <div>
            <input
              disabled
              name="trialName"
              type="input"
              // autoComplete='off'
              {...register('trialName')}
              onFocus={clearRegErrorMessage}
              className={`form-input ${errors.trialName ? 'is-invalid' : ''}`}
            />
          </div>
        </div>
        <div className="form-item">
          <label>*Email of the added user</label>
          <div className="form-error" aria-live="polite">
            {errors.userEmail?.message}
          </div>
          <div>
            <select
              className="signup-form-select"
              name="userEmail"
              type="text"
              {...register('userEmail')}
            >
              <option value="">Select a user</option>
              {teamUsers &&
                teamUsers.map((item) => (
                  <option key={item.email} value={item.email}>
                    {item.email}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </fieldset>

      <div className="form-group icons-flex-container">
        <button type="submit" className="submit-btn">
          <AiOutlineCheck
            className="submit-icon"
            data-tip
            data-for="submit-trial_users"
          />
          <ReactTooltip
            id="submit-trial_users"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Submit
          </ReactTooltip>
        </button>
        <button
          type="button"
          className="cancel-btn"
          onClick={handleCancelTrialUsersEdit}
        >
          <AiOutlineClose
            className="cancel-icon"
            data-tip
            data-for="cancel-trial-users"
          />
          <ReactTooltip
            id="cancel-trial-users"
            aria-haspopup="true"
            effect="float"
            className="custom-tooltip"
          >
            Cancel
          </ReactTooltip>
        </button>
      </div>
    </form>
  );
}

NewTrialUsersForm.propTypes = {
  trialInfo: PropTypes.object,
  getTrialUsersDataCallback: PropTypes.func,
  cancelTrialUsersEditCallback: PropTypes.func,
};

export default NewTrialUsersForm;
