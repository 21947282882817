import {FitbitApi} from '../api/FitbitApi';

// Get data of a single participant from Fitbit based on the information in the query
// TODO: add error display to the user
const GetParticipantDataFromFitbit = async (participant, queryStr) => {
  let participantData = {data: null, errors: null};
  try {
    // Get the data from Fitbit server
    let result = await FitbitApi.GetParticipantDataApi(
      participant.id,
      participant.email,
      queryStr
    );
    // Add data and errors to participantData
    if (result.data) {
      participantData.data = result.data;
    }
    if (result.errors) {
      participantData.errors = result.errors;
    }
  } catch (error) {
    console.error(error.message);
    // TODO: decide what kind of errors to display here
    if (error && error.message) {
      console.error(error.message);
      if (error.message === 'Too Many Requests') {
        participantData.errors = error.message;
      } else {
        participantData.errors =
          'Error3: getting data from Fitbit server failed!';
      }
    } else {
      participantData.errors =
        'Error4: getting data from Fitbit server failed!';
    }
  }
  return participantData;
};

// Get data from local DB of a single participant
const GetParticipantFitbitDataLocalDB = async (participant) => {
  let participantData = {data: null, errors: null};
  try {
    // Get the data from Fitbit server
    let res = await FitbitApi.GetFitbitDataLocalDBApi(participant.id);
    // Add data and errors to participantData
    let data = await res.json(res);
    if (data) {
      participantData.data = data;
    } else {
      throw new Error('Error: Could not get data from local DB');
    }
  } catch (error) {
    console.error(error.message);
    participantData.errors = 'Error: getting Fitbit data from local DB failed!';
  }
  return participantData;
};

export {GetParticipantDataFromFitbit, GetParticipantFitbitDataLocalDB};
