import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useAuth} from '../authorization/AuthContext';
import useApi from '../api/useApi';
import Table from '../table/Table';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {FitbitApi} from '../fitbit/api/FitbitApi';
import DevicesInfo from './DevicesInfo';
import AddParticipantPage from './newParticipant/AddParticipantPage';
import EditParticipantPage from './EditParticipantPage';
import DeleteParticipantPage from './DeleteParticipantPage';

import './ParticipantDataPage.css';

const ParticipantsPage = ({trialInfo}) => {
  const {trialId} = trialInfo;
  let navigate = useNavigate();
  const [participantsData, setParticipantsData] = useState();
  const [error, setError] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const [url, setUrl] = useState(null);

  // Make sure that we have the trial info before sending to the server
  useEffect(() => {
    if (trialId) {
      setUrl(`/trialParticipants/${trialId}`);
    }
  }, [trialId]);

  // Get participants info from location
  const {data, loading, error: apiErrors} = useApi(url);

  const getFitbitDevicesData = async (participantId, email) => {
    const queryStr = '/devices.json';
    let res = await FitbitApi.GetParticipantDataApi(
      participantId,
      email,
      queryStr
    );
    return res;
  };

  useEffect(() => {
    try {
      const getParticipantAuthDevices = async () => {
        try {
          // Add a new item 'auth_devices', to each object,
          // and insert into it the list of authorized devices
          await Promise.all(
            data.map(async (participant, key) => {
              data[key].auth_devices = [];
              let res = await fetch(
                `/fitbitIsParticipantAuth/${participant.id}`,
                {
                  method: 'GET',
                }
              );
              if (res.status === 200) {
                const results = await res.json(res);
                if (results.message === 'found') {
                  res = await getFitbitDevicesData(
                    participant.id,
                    participant.email
                  );
                  if (res.errors) {
                    data[key].auth_devices.push({
                      device: 'Fitbit',
                      deviceVersion: 'Err', // Indicates error
                    });
                  } else if (res.data && Array.isArray(res.data)) {
                    res.data.forEach((item) => {
                      const {
                        battery,
                        batteryLevel,
                        deviceVersion,
                        type,
                        lastSyncTime,
                      } = item;

                      // const formattedDateTime = DateTime.fromISO(
                      //   lastSyncTime
                      // ).toLocaleString(
                      //   DateTime.DATETIME_MED // DateTime.DATETIME_FULL
                      // );

                      data[key].auth_devices.push({
                        device: 'Fitbit',
                        batteryStatus: battery,
                        batteryLevel: batteryLevel,
                        deviceVersion: deviceVersion,
                        deviceType: type,
                        lastSyncTime: lastSyncTime,
                      });
                    });
                  }
                }
              }
              res = await fetch(`/omronIsParticipantAuth/${participant.id}`, {
                method: 'GET',
              });
              if (res.status === 200) {
                const results = await res.json(res);
                if (results.message === 'found') {
                  data[key].auth_devices.push({device: 'Omron'});
                }
              }
            })
          );
          // To force re-rendering on every change of data,
          // we use the spread operator to create a copy of the 'data' array
          setParticipantsData([...data]);
        } catch (error) {
          console.error(error);
        }
      };

      let repeat;
      // Periodically refresh the data for all the authorized devices in the table
      const getAuthDevicesPeriodically = async () => {
        repeat = setTimeout(getAuthDevicesPeriodically, 300000); // request again in 5 mins
        await getParticipantAuthDevices();
        console.log(`getParticipantAuthDevices ${new Date()}`);
      };

      const getAdditionalData = async () => {
        try {
          setLocalLoading(true);
          await getAuthDevicesPeriodically();
        } catch (error) {
          console.error(error);
        } finally {
          setLocalLoading(false);
        }
      };
      if (data) {
        setParticipantsData(data);
        getAdditionalData();
      }

      // Clean up timeout if the component was unmounted
      return () => {
        if (repeat) {
          clearTimeout(repeat);
        }
      };
    } catch (error) {
      console.error(error);
      setError("Something went wrong! We could not find participants' info.");
    }
  }, [data]);

  // Handle click event on table row or multiple rows
  const participantsDataCallback = (rows, info) => {
    // Multiple participants
    if (info === 'multipleRows') {
      // Get data from all the selected rows
      const data = rows.map((item) => {
        return {id: item.original.id, email: item.original.email};
      });
      navigate('/ParticipantsDataPage', {state: data});
    } else {
      // A single participant
      navigate('/ParticipantOverview', {state: rows});
    }
  };

  const handleSubmitNewParticipant = (participant) => {
    try {
      const {participantId, email, age, gender, notes} = participant;
      const updatedParticipants = [
        ...participantsData,
        {
          id: participantId,
          email: email,
          age: age,
          gender: gender,
          notes: notes,
        },
      ];
      setParticipantsData(updatedParticipants);
    } catch (error) {
      setError(
        'The participant has been added, but the display is wrong. Please refresh the page!'
      );
    }
  };

  const handleSubmitDeleteParticipant = (participant) => {
    const updatedParticipants = participantsData.filter((item) => {
      return item.id !== participant.id;
    });
    setParticipantsData(updatedParticipants);
  };

  const handleSubmitEditParticipant = (participant) => {
    const updatedTrials = participantsData.map((item) => {
      return item.id === participant.id ? participant : item;
    });

    setParticipantsData(updatedTrials);
  };

  const {userRole} = useAuth(); // Get user's role

  const Columns = [
    {Header: 'Id', accessor: 'id', disableFilters: true},
    {Header: 'Email', accessor: 'email', disableFilters: true},
    {Header: 'Gender', accessor: 'gender', disableFilters: true},
    {Header: 'Age', accessor: 'age', disableFilters: true},
    {
      Header: 'Notes',
      accessor: 'notes',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Authorized Devices',
      accessor: 'auth_devices',
      disableFilters: true,
      disableSortBy: true,
      Cell: function Cell(row) {
        let participant = row.row.original;

        // We want to display in the table only the first device, the user can also scroll down to see
        // all the other devices
        const [height, setHeight] = useState('0');
        // To get a reference to the first item in the devices list
        const firstItemRef = useRef(null);
        // console.log(height);

        // Set the height based on the scroll height of the first item
        useEffect(() => {
          if (firstItemRef.current) {
            setHeight(
              firstItemRef.current.children[0].scrollHeight + 10 + 'px'
            );
          }
        }, [participant.auth_devices]);

        if (
          participant &&
          participant.auth_devices &&
          participant.auth_devices.length > 0
        ) {
          return (
            <div
              className="scrollable-cell"
              id="scrollable-cell1"
              style={{
                // Set the maxHeight of the container based on the dynamic height calculated from the first item
                // but also keep a minimum height
                maxHeight: height,
                minHeight: '50px',
              }}
            >
              <div ref={firstItemRef}>
                <DevicesInfo participant={participant} />
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: '',
      accessor: 'links',
      disableFilters: true,
      disableSortBy: true,
      Cell: function Cell(row) {
        let participant = row.row.original;
        return (
          <div>
            <br />
            <div>
              {/* Note: This feature is not in use at this point Shlomi 12/13/2023 */}
              {/* <Link
              className="link"
              to="/ParticipantDataPage"
              state={{participant: participant}}
            >
              Participant Data
            </Link> */}
            </div>
            <br />
            {/* Note: This feature is not in use at this point Shlomi 12/13/2023 */}
            {/* <div>
              <Link
                className="link"
                to="/ParticipantOverview"
                state={{participant: participant}}
              >
                Participant Overview
              </Link>
            </div> */}
            {(userRole === 'Sys_Director' ||
              userRole === 'Admin' ||
              userRole === 'User') && (
              <div>
                <Link
                  className="link"
                  to="/Participant"
                  state={{participant: participant}}
                >
                  Manage Participant
                </Link>
              </div>
            )}
            <br />
            <div>
              <EditParticipantPage
                participant={participant}
                editCallback={handleSubmitEditParticipant}
              />
              <DeleteParticipantPage
                participant={participant}
                deleteCallback={handleSubmitDeleteParticipant}
              />
            </div>
          </div>
        );
      },
      filterable: true,
    },
  ];

  if (loading || localLoading) {
    return <div className="spinnerModal"></div>;
  }
  return (
    <>
      {apiErrors && <DisplayErrors errors={apiErrors} />}
      {error && <DisplayErrors errors={error} />}
      <div className="trial-participants-container">
        <h2>Participants</h2>
        <AddParticipantPage
          trialInfo={trialInfo}
          getParticipantDataCallback={handleSubmitNewParticipant}
        />
      </div>
      {participantsData && participantsData.length > 0 && (
        <div className="tableContainer">
          <Table
            deviceName={null}
            tableName={'participantsTable'}
            data={participantsData}
            columns={Columns}
            dataCallback={participantsDataCallback}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: true,
              maxNumOfSelectedRows: 10,
              displayGlobalFilter: true,
              displayPerColFilter: false,
              exportDataToFile: false,
              userCanHideCols: true,
            }}
            hideColsProps={{
              hiddenColsOnInitialState: ['selection'],
              storageUserHiddenColsKey: 'dashboardTableHiddenCols',
              colsListCanBeHiddenByUser: ['id', 'gender', 'age', 'notes'],
            }}
          />
        </div>
      )}
    </>
  );
};

ParticipantsPage.propTypes = {
  trialInfo: PropTypes.object,
};

export default ParticipantsPage;
