import React from 'react';
import PropTypes from 'prop-types';

import UserTeamsForm from './UserTeamsForm';
import {fetchApiSet} from '../api/fetchApi';

function UserTeams({user, dataCallback}) {
  async function handleTeamSelected(teamInfo) {
    console.log(`handle submit: ${teamInfo}`);
    try {
      const response = await fetchApiSet('/selectTeam', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          teamId: teamInfo.teamId,
          teamName: teamInfo.teamName,
          role: teamInfo.teamRole,
        }),
      });
      if (!response) {
        throw new Error('Failed');
      }
      console.log('Successful');
      dataCallback(teamInfo.teamId);
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  }

  return (
    <>
      <div>
        {/* <h2>Teams/Projects</h2> */}
        <>
          <UserTeamsForm user={user} getDataCallback={handleTeamSelected} />
        </>
      </div>
    </>
  );
}

UserTeams.propTypes = {
  dataCallback: PropTypes.func,
  user: PropTypes.object,
};

export default UserTeams;
