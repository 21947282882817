import {DateTime} from 'luxon';

import {
  DateToISODateOnlyShort,
  DateToTimeOnlyShort,
} from '../../../services/date&time/DateTimeUtils';
import {timeRangeToStartDate} from '../../../services/date&time/DateTimeUtils';

class Response {
  constructor() {
    this.data = null;
    this.error = '';
  }
}

class FitbitQueryData {
  constructor() {
    this.activityResourcePath = '';
    this.bodyHeartPeriod = '';
    this.bodyResourcePath = '';
    this.category = '';
    this.endDate = '';
    this.heartDetailLevel = '';
    this.includeTime = false;
    this.maxActivityLogs = 1;
    this.maxSleepLogs = 10;
    this.period = '';
    this.sleepOptions = '';
    this.startDate = '';
  }
}

const overviewQueryBuilder = (data, response) => {
  // Overview
  response.data = `/overview`;
  console.log(data);
  return response;
};

const activityQueryBuilder = (data, response) => {
  response.data = '/activities';
  if (data.activityResourcePath) {
    if (data.activityResourcePath === 'dailySummary') {
      // Daily Activity Summary
      if (data.startDate) {
        response.data += `/date/${DateToISODateOnlyShort(data.startDate)}`;
      } else {
        response.error = 'Please select a date';
      }
      if (response.data.length > 0) {
        response.data += '.json';
      }
    } else if (data.activityResourcePath === 'logsList') {
      // Activity Logs List
      response.data += '/list.json';
      if (data.startDate) {
        response.data += `?afterDate=${DateToISODateOnlyShort(
          data.startDate
        )}&sort=asc&offset=0`;
      } else if (data.endDate) {
        response.data += `?beforeDate=${DateToISODateOnlyShort(
          data.endDate
        )}&sort=desc&offset=0`;
      } else {
        response.error = 'Please select before or after date';
      }
      if (response.data.length > 0) {
        if (data.maxActivityLogs) {
          response.data += `&limit=${data.maxActivityLogs}`;
        } else {
          response.error = 'Please select max activity logs';
        }
      }
    } else {
      // Activity Time Series
      response.data += `/${data.activityResourcePath}`;
      if (data.period && data.period !== 'customDates') {
        let endDate = DateTime.now().toISODate();
        response.data += `/date/${endDate}/${data.period}`;
      } else if (data.period === 'customDates') {
        if (data.startDate) {
          data.startDate = DateTime.fromJSDate(data.startDate).toFormat(
            'yyyy-MM-dd'
          );
          response.data += `/date/${data.startDate}`;
          if (data.endDate) {
            data.endDate = DateTime.fromJSDate(data.endDate).toFormat(
              'yyyy-MM-dd'
            );
            response.data += `/${data.endDate}`;
          } else {
            response.error = 'Please select an end date';
          }
        } else {
          response.error = 'Please select a start date';
        }
      } else {
        response.error = 'Please select a period';
      }
      if (response.data.length > 0) {
        response.data += '.json';
      }
    }
  } else {
    response.error = 'Please select a resource';
  }
  if (response.error.length > 0) {
    response.data = '';
  }
  return response;
};

const heartQueryBuilder = (data, response) => {
  // Heart Rate
  response.data = `/activities/heart`;
  if (data.heartOptions) {
    if (data.period && data.period !== 'customDates') {
      let endDate = DateTime.now().toISODate();
      response.data += `/date/${endDate}/${data.period}`;
      if (data.heartDetailLevel) {
        response.data += `/${data.heartDetailLevel}`;
      }
      if (data.includeTime) {
        if (data.startTime && data.endTime) {
          response.data += `/time/${DateToISODateOnlyShort(
            data.startTime
          )}/${DateToISODateOnlyShort(data.endTime)}`;
        } else {
          response.error = 'Please add start and end time';
        }
      }
    } else if (data.period === 'customDates') {
      if (data.endDate) {
        data.endDate = DateTime.fromJSDate(data.endDate).toFormat('yyyy-MM-dd');
        response.data += `/date/${data.endDate}`;
        if (data.startDate) {
          data.startDate = DateTime.fromJSDate(data.startDate).toFormat(
            'yyyy-MM-dd'
          );
          response.data += `/${data.startDate}`;
          if (data.heartDetailLevel) {
            response.data += `/${data.heartDetailLevel}`;
          }
          if (data.includeTime) {
            if (data.startTime && data.endTime) {
              response.data += `/time/${DateToISODateOnlyShort(
                data.startTime
              )}/${DateToISODateOnlyShort(data.endTime)}`;
            } else {
              response.error = 'Please add start and end time';
            }
          }
        } else {
          response.error = 'Please select a start date';
        }
      } else {
        response.error = 'Please select start and end dates';
      }
    } else {
      response.error = 'Please select a period';
    }
  } else {
    response.error = 'Please select an Heart Option';
  }
  if (response.data.length > 0) {
    response.data += '.json';
  }
  if (response.error.length > 0) {
    response.data = '';
  }
  return response;
};

const bodyQueryBuilder = (data, response) => {
  // Body & Weight
  response.data = `/body`;
  if (data.bodyResourcePath) {
    if (
      data.bodyResourcePath === 'log/weight/goal' ||
      data.bodyResourcePath === 'log/fat/goal'
    ) {
      response.data += `/${data.bodyResourcePath}`;
    } else {
      response.data += `/${data.bodyResourcePath}`;
      if (data.period && data.period !== 'customDates') {
        let endDate = DateTime.now().toISODate();
        response.data += `/date/${endDate}/${data.period}`;
      } else if (data.period === 'customDates') {
        if (data.startDate) {
          data.startDate = DateTime.fromJSDate(data.startDate).toFormat(
            'yyyy-MM-dd'
          );
          response.data += `/date/${data.startDate}`;
          if (data.endDate) {
            data.endDate = DateTime.fromJSDate(data.endDate).toFormat(
              'yyyy-MM-dd'
            );
            response.data += `/${data.endDate}`;
          } else {
            response.error = 'Please select an end date';
          }
        } else {
          response.error = 'Please select a start date';
        }
      } else {
        response.error = 'Please select a period';
      }
    }
  } else {
    response.error = 'Please select a resource';
  }
  if (response.data.length > 0) {
    response.data += '.json';
  }
  if (response.error.length > 0) {
    response.data = '';
  }
  return response;
};

const sleepQueryBuilder = (data, response) => {
  // Sleep
  response.data = `/sleep`;
  if (data.sleepOptions === 'duration') {
    // Sleep Duration (Time Series)
    if (data.period && data.period !== 'customDates') {
      // Fitbit doesn't support period in this case
      // We still want to support it so, we need to convert from period to date
      let endDate = DateTime.now();
      let startDate = null;
      startDate = timeRangeToStartDate(data.period, endDate);
      // Convert to ISO 8601
      endDate = endDate.toISODate();
      response.data += `/date/${startDate}/${endDate}`;
    } else if (data.period === 'customDates') {
      if (data.startDate) {
        data.startDate = DateTime.fromJSDate(data.startDate).toFormat(
          'yyyy-MM-dd'
        );
        response.data += `/date/${data.startDate}`;
        if (data.endDate) {
          data.endDate = DateTime.fromJSDate(data.endDate).toFormat(
            'yyyy-MM-dd'
          );
          response.data += `/${data.endDate}`;
        } else {
          response.error = 'Please select an end date';
        }
      } else {
        response.error = 'Please select a start date';
      }
    } else {
      response.error = 'Please select a period';
    }
    if (response.data.length > 0) {
      response.data += '.json';
    }
  } else if (data.sleepOptions === 'logs') {
    // Sleep Logs
    if (data.startDate) {
      response.data += `/date/${DateToISODateOnlyShort(data.startDate)}`;
      if (data.endDate) {
        response.data += `/${DateToISODateOnlyShort(data.endDate)}`;
      } else {
        response.error = 'Please select an end date';
      }
    } else {
      response.error = 'Please select a start date';
    }
    if (response.data.length > 0) {
      response.data += '.json';
    }
  } else if (data.sleepOptions === 'logsList') {
    // Logs List
    response.data += '/list.json';
    if (data.startDate) {
      response.data += `?afterDate=${DateToISODateOnlyShort(
        data.startDate
      )}&sort=asc&offset=0`;
    } else if (data.endDate) {
      response.data += `?beforeDate=${DateToISODateOnlyShort(
        data.endDate
      )}&sort=desc&offset=0`;
    } else {
      response.error = 'Please select after or before date';
    }
    if (data.maxSleepLogs) {
      response.data += `&limit=${data.maxSleepLogs}`;
    } else {
      response.error = 'Please select max number of sleep logs';
    }
  } else if (data.sleepOptions === 'logSleep') {
    // Log Sleep
    // TODO: this one is not working check with Fitbit why!!!
    response.data += '.json';
    if (data.startDate) {
      if (data.includeTime) {
        response.data += `?date=${DateToISODateOnlyShort(
          data.startDate
        )}&startTime=${DateToTimeOnlyShort(data.startDate)}&duration=7200000`;
      } else {
        response.error = 'Please select include time and start time';
      }
    } else {
      response.error = 'Please select a start date';
    }
  } else {
    response.error = 'Please select a sleep option';
  }
  if (response.error.length > 0) {
    response.data = '';
  }
  return response;
};

const foodsQueryBuilder = (data, response) => {
  console.log(data, response);
  // Heart Rate
  response.data = `/foods/log`;
  if (data.foodsOptions) {
    response.data += `/${data.foodsOptions}`;
    if (data.period && data.period !== 'customDates') {
      let endDate = DateTime.now().toISODate();
      response.data += `/date/${endDate}/${data.period}`;
    } else if (data.period === 'customDates') {
      if (data.endDate) {
        data.endDate = DateTime.fromJSDate(data.endDate).toFormat('yyyy-MM-dd');
        response.data += `/date/${data.endDate}`;
        if (data.startDate) {
          data.startDate = DateTime.fromJSDate(data.startDate).toFormat(
            'yyyy-MM-dd'
          );
          response.data += `/${data.startDate}`;
          if (data.heartDetailLevel) {
            response.data += `/${data.heartDetailLevel}`;
          }
          if (data.includeTime) {
            if (data.startTime && data.endTime) {
              response.data += `/time/${DateToISODateOnlyShort(
                data.startTime
              )}/${DateToISODateOnlyShort(data.endTime)}`;
            } else {
              response.error = 'Please add start and end time';
            }
          }
        } else {
          response.error = 'Please select a start date';
        }
      } else {
        response.error = 'Please select start and end dates';
      }
    } else {
      response.error = 'Please select a period';
    }
  } else {
    response.error = 'Please select a Foods Option';
  }
  if (response.data.length > 0) {
    response.data += '.json';
  }
  if (response.error.length > 0) {
    response.data = '';
  }
  return response;
};

// Build query strings for each Fitbit scenario
const FitbitQueryBuilder = (data) => {
  let response = new Response();

  try {
    switch (data.category) {
      case 'overview':
        return overviewQueryBuilder(data, response);
      case 'activities':
        return activityQueryBuilder(data, response);
      case 'heart':
        return heartQueryBuilder(data, response);
      case 'body':
        return bodyQueryBuilder(data, response);
      case 'sleep':
        return sleepQueryBuilder(data, response);
      case 'foods':
        return foodsQueryBuilder(data, response);
      default:
        response.error = 'Please select a category';
        return response;
    }
  } catch (error) {
    console.log(error);
    response.error = error;
    return response;
  }
};

export {FitbitQueryBuilder, FitbitQueryData};
