import React from 'react';
import PropTypes from 'prop-types';
import {useFormContext} from 'react-hook-form';

import './RadioButtonsList.css';
import './SmallRadioBtnsList.css';

const RadioButtonsList = ({
  list,
  title,
  name,
  btnType,
  radioButtonClickedCallback,
  radioButtonChangedCallback,
}) => {
  const {register} = useFormContext();

  if (!btnType) {
    btnType === 'normal';
  }

  let idx = 0;

  return (
    <div>
      <h4>{title}</h4>
      {list && (
        <div className={btnType === 'small' ? 'radio-btns-flex-container' : ''}>
          {list.map((item) => (
            <div key={idx++} {...register(name)} className="">
              <label
                htmlFor={item.id}
                className={
                  btnType === 'small'
                    ? 'small-radio-btn-container'
                    : 'radio-btn-container'
                }
              >
                <input
                  type="radio"
                  id={item.id}
                  value={item.value}
                  name={name}
                  // defaultChecked={item.name === item.value}
                  // checked={item.checked === item.id ? false : true}
                  onChange={radioButtonChangedCallback}
                  onClick={radioButtonClickedCallback}
                ></input>
                <span className="span">{item.label}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

RadioButtonsList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  name: PropTypes.string,
  btnType: PropTypes.string,
  radioButtonClickedCallback: PropTypes.func,
  radioButtonChangedCallback: PropTypes.func,
};

RadioButtonsList.displayName = 'RadioButtonsList';

export default RadioButtonsList;
