import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import SFAValidateForm from './SFAValidateForm';
import {fetchApiSet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useAuth} from '../authorization/AuthContext';

const SFAValidatePage = () => {
  // Destructuring logout and sfaValidate from AuthContext
  const {logout, sfaValidate} = useAuth();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isAlreadyValidated, setIsAlreadyValidated] = useState(null);

  useEffect(() => {
    const isSfaAlreadyValidated = async () => {
      try {
        setLoading(true);
        const results = await fetchApiSet('/auth//isSfaAlreadyValidated', {
          method: 'GET',
        });

        if (results.success) {
          setIsAlreadyValidated(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    isSfaAlreadyValidated();
  }, []);

  const handleValidateSFATokenReq = async (data) => {
    try {
      setLoading(true);
      let res = await fetchApiSet('/auth/validateTOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sfaToken: data.sfaToken,
          skipSFA: data.skipSFA || false,
        }),
      });
      if (res.errors) {
        throw new Error(`Error: Could not validate sfa token!`);
      } else {
        // console.log(res);
        if (res.success === true) {
          console.log('Validating SFA token was successful');
          sfaValidate('Authorized');
        } else {
          throw new Error(`Error: Validating sfa token failed!`);
        }
      }
    } catch (error) {
      console.error(error);
      if (error?.status === 'ACCOUNT_LOCKED') {
        const errMsg =
          error.message || 'Account locked due to too many failed attempts';
        // Perform logout action
        logout(errMsg);
      } else if (error?.displayToUser && error?.message) {
        setErrors(error.message);
      } else {
        setErrors('Error: validating sfa token request failed!');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendSFACodeByEmailClicked = async () => {
    sfaValidate('SFAValidateEmailCodeNeeded');
    navigate('/SFASendCodeViaMsgPage');
  };

  return (
    <div className="centered-text">
      {loading ? <div className="spinnerModal"></div> : ''}
      <h1>Second-Factor Authentication</h1>
      {errors && <DisplayErrors errors={errors} />}
      {!isAlreadyValidated && (
        <>
          <p>
            Please enter the code provided by your mobile authenticator app and
            then click Submit.
          </p>
          <SFAValidateForm getDataCallback={handleValidateSFATokenReq} />
          <div>
            <button
              type="button"
              className="simple-btn"
              onClick={() => handleSendSFACodeByEmailClicked()}
            >
              Send code by email
            </button>
          </div>
        </>
      )}{' '}
      {isAlreadyValidated && (
        <p>Your second-factor authentication has already been validated!</p>
      )}
    </div>
  );
};

export default SFAValidatePage;
