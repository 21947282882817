import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AiOutlineEdit, AiOutlineDelete} from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import {useAuth} from '../authorization/AuthContext';
import {setTrialApi, updateTrialApi, deleteTrialApi} from '../trials/trialsApi';
import NewTrialForm from './NewTrialForm';
import EditTrialForm from './EditTrialForm';
import DeleteTrialForm from './DeleteTrialForm';
import Table from '../table/Table';
import useApi from '../api/useApi';
import Modal from '../modal/Modal';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';

import './Trials.css';

function Trials({user}) {
  let navigate = useNavigate();
  const {id: userId, email} = user;
  const {userRole} = useAuth(); // Get user's role

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [trialAction, setTrialAction] = useState(null);
  const [singleTrialData, setSingleTrialData] = useState({});
  const [trialsData, setTrialsData] = useState([]);

  // Retrieve all the trials associated with the current user from the database.
  const {data, error: apiErrors} = useApi(`/userTrials/`);

  useEffect(() => {
    if (data) {
      setTrialsData(data);
    }
  }, [data]);

  const setStateAddTrial = (
    trialId,
    trialNumber,
    trialName,
    trialNotes,
    email
  ) => {
    const updatedTrials = [
      ...trialsData,
      {
        trialId: trialId,
        trialNumber: trialNumber,
        trialName: trialName,
        trialNotes: trialNotes,
        trialOwnerEmail: email,
      },
    ];
    setTrialsData(updatedTrials);
  };

  const handleSubmitDataNewTrial = async (data) => {
    // Upon form submit send an api request to add the trial to the db
    try {
      setLoading(true);
      data.email = email;
      data.userId = userId;
      // const {trialNumber, trialName, trialNotes} = data;
      const res = await setTrialApi(data);
      if (res.errors) {
        throw new Error('Adding a new trial failed');
      } else {
        const trialId = res.trialId;
        const {trialNumber, trialName, trialNotes} = data;
        setStateAddTrial(trialId, trialNumber, trialName, trialNotes, email);
      }
    } catch (error) {
      if (error && error.displayToUser && error.message) {
        setError(`Error:  ${error.message}`);
      } else {
        if (error && error.displayToUser && error.message) {
          setError(`Error:  ${error.message}`);
        } else {
          setError('Adding a new trial failed');
        }
      }
    } finally {
      setTrialAction(null);
      setLoading(false);
    }
  };

  const setStateUpdateTrial = (data) => {
    const {trialId, trialNumber, trialName, trialNotes} = data;
    const newTrial = {
      trialId: trialId,
      trialNumber: trialNumber,
      trialName: trialName,
      trialNotes: trialNotes,
      trialOwnerEmail: email,
    };
    const updatedTrials = trialsData.map((trial) => {
      return trial.trialId === trialId ? newTrial : trial;
    });

    setTrialsData(updatedTrials);
  };

  const handleSubmitDataEditTrial = async (data) => {
    // Upon form submit send an api request to update the db
    try {
      setLoading(true);
      data.email = email;
      const res = await updateTrialApi(data);
      if (res.errors) {
        throw new Error(res.errors);
      }
      setStateUpdateTrial(data);
    } catch (error) {
      if (error && error.displayToUser && error.message) {
        setError(`Error:  ${error.message}`);
      } else {
        setError('Updating trial failed');
      }
    } finally {
      setTrialAction(null);
      setLoading(false);
    }
  };

  const setStateDeleteTrial = (trialId) => {
    // Delete the trial
    const updatedTrials = trialsData.filter((trial) => {
      return trial.trialId !== trialId;
    });
    setTrialsData(updatedTrials);
  };

  const handleSubmitDeleteTrial = async (data) => {
    // Upon form submit send an api request to delete the trial from the db
    try {
      setLoading(true);
      const res = await deleteTrialApi(data);
      if (res.errors) {
        if (res.errors === 'Unauthorized') {
          throw new Error('Unauthorized');
        }
        throw new Error('Deleting trial failed');
      }
      setStateDeleteTrial(data.trialId);
    } catch (error) {
      if (error && error.displayToUser && error.message) {
        setError(`Error:  ${error.message}`);
      } else {
        setError('Deleting current trial failed');
      }
    } finally {
      setTrialAction(null);
      setLoading(false);
    }
  };

  const handleAddNewTrial = () => {
    setTrialAction('addTrial');
  };

  const handleEditTrial = (row) => {
    setSingleTrialData({
      trialId: row.trialId,
      trialNumber: row.trialNumber,
      trialName: row.trialName,
      trialNotes: row.trialNotes,
    });
    setTrialAction('editTrial');
  };

  const handleDeleteTrial = (row) => {
    setSingleTrialData({
      trialId: row.trialId,
      trialNumber: row.trialNumber,
      trialName: row.trialName,
      trialNotes: row.trialNotes,
    });
    setTrialAction('deleteTrial');
  };

  const cancelEditTrial = () => {
    setTrialAction(null);
  };

  const closeModal = () => {
    setTrialAction(null);
  };

  const closeErrorModal = () => {
    setError(false);
  };

  const handleTrialAction = () => {
    switch (trialAction) {
      case 'addTrial':
        return (
          <Modal title={'New Trial'} text={''} closeModalCallback={closeModal}>
            <NewTrialForm
              getTrialsDataCallback={handleSubmitDataNewTrial}
              cancelTrialsEditCallback={cancelEditTrial}
            />
          </Modal>
        );
      case 'editTrial':
        return (
          <Modal title={'Edit Trial'} closeModalCallback={closeModal}>
            <EditTrialForm
              trial={singleTrialData}
              getTrialsDataCallback={handleSubmitDataEditTrial}
              cancelTrialsEditCallback={cancelEditTrial}
            />
          </Modal>
        );
      case 'deleteTrial':
        return (
          <Modal title={'Delete Trial?'} closeModalCallback={closeModal}>
            <DeleteTrialForm
              trial={singleTrialData}
              getTrialsDataCallback={handleSubmitDeleteTrial}
              cancelTrialsDeleteCallback={cancelEditTrial}
            />
          </Modal>
        );
    }
  };

  // Handle cell click trial selection
  const handleCellClickTrialSelection = (row) => {
    // Call the current trial page
    navigate('/TrialPage', {state: row});
  };

  // Trials columns to display in Table component
  const TrialsColumns = [
    {
      Header: 'Trial Number',
      accessor: 'trialNumber',
      Cell: function Cell(row) {
        let data = row.row.original;
        return (
          <div
            className="select_cell"
            onClick={() => handleCellClickTrialSelection(data)}
          >
            {data.trialNumber}
          </div>
        );
      },
    },
    {
      Header: 'Trial Name',
      accessor: 'trialName',
      Cell: function Cell(row) {
        let data = row.row.original;
        return (
          <div
            className="select_cell"
            onClick={() => handleCellClickTrialSelection(data)}
          >
            {data.trialName}
          </div>
        );
      },
    },
    // We don't support per trial roles yet!!! Shlomi 01/02/2024
    // {
    //   Header: 'Role',
    //   accessor: 'role',
    //   Cell: function Cell(row) {
    //     let data = row.row.original;
    //     return (
    //       <div
    //         className="select_cell"
    //         onClick={() => handleCellClickTrialSelection(data)}
    //       >
    //         {data.role}
    //       </div>
    //     );
    //   },
    // },
    {
      Header: 'Notes',
      accessor: 'trialNotes',
      Cell: function Cell(row) {
        let data = row.row.original;
        return (
          <div
            className="select_cell"
            onClick={() => handleCellClickTrialSelection(data)}
          >
            {data.trialNotes}
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'links',
      Cell: function Cell(row) {
        let trialRow = row.row.original;
        return (
          <div>
            <div>
              {(userRole === 'Sys_Director' ||
                userRole === 'Admin' ||
                userRole === 'User') && (
                <button
                  type="button"
                  className="trials-flex-item icon-btn-no-background"
                  onClick={() => handleEditTrial(trialRow)}
                >
                  <AiOutlineEdit
                    className="edit-trials-icon"
                    data-tip
                    data-for="edit-trial"
                  />
                  <ReactTooltip
                    id="edit-trial"
                    aria-haspopup="true"
                    effect="float"
                    className="custom-tooltip"
                  >
                    Edit Trial
                  </ReactTooltip>
                </button>
              )}
              {(userRole === 'Sys_Director' || userRole === 'Admin') && (
                <button
                  type="button"
                  className="trials-flex-item icon-btn-no-background"
                  onClick={() => handleDeleteTrial(trialRow)}
                >
                  <AiOutlineDelete
                    className="delete-trials-icon"
                    data-tip
                    data-for="delete-trial"
                  />
                  <ReactTooltip
                    id="delete-trial"
                    aria-haspopup="true"
                    effect="float"
                    className="custom-tooltip"
                  >
                    Delete Trial
                  </ReactTooltip>
                </button>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Trial Owner's Email",
      accessor: 'trialOwnerEmail',
    },
  ];

  return (
    <div>
      {loading ? <div className="spinnerModal"></div> : ''}
      {apiErrors && <DisplayErrors errors={apiErrors} />}
      {error && (
        <div className="error-message centered-text">
          <Modal
            title={''}
            text={error}
            closeModalCallback={closeErrorModal}
          ></Modal>
        </div>
      )}
      {trialAction && handleTrialAction()}
      <div id="trialsContainer">
        <div className="flex-title">
          <h2 className="trials-flex-item">Trials</h2>
          {(userRole === 'Sys_Director' || userRole === 'Admin') && (
            <button
              className="trials-flex-item new-trial-btn  clickable-title"
              onClick={handleAddNewTrial}
            >
              Add New Trial
            </button>
          )}
        </div>
        {trialsData && trialsData.length > 0 && (
          <div>
            <Table
              deviceName={null}
              tableName={'trialsTable'}
              data={trialsData}
              columns={TrialsColumns}
              dataCallback={''}
              features={{
                singleRowSelection: false,
                multipleRowsSelection: false,
                maxNumOfSelectedRows: null,
                displayGlobalFilter: true,
                pageSize: 10,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

Trials.propTypes = {
  user: PropTypes.object,
  trials: PropTypes.object,
  cancelTrialsEditCallback: PropTypes.func,
  state: PropTypes.object,
};

export default Trials;
