import React, {useState, useEffect, useMemo} from 'react';

import Table from '../../table/Table';

function DisplayUsersData() {
  const [userTableData, setUserTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/usersData');
        const result = await response.json();
        setUserTableData(result);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Num', // Show the row number
        accessor: (_, index) => index + 1, // Calculate row number
        id: 'rowNumber',
      },
      {
        Header: 'User ID',
        accessor: 'user_id',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Team Name',
        accessor: 'team_name',
      },
      {
        Header: 'SFA Required',
        accessor: 'sfa_required',
      },
      {
        Header: 'SFA Enabled',
        accessor: 'sfa_enabled',
      },
      {
        Header: 'SFA Type',
        accessor: 'sfa_type',
      },
      {
        Header: 'Create Time',
        accessor: 'create_time',
      },
      {
        Header: 'Organization',
        accessor: 'organization',
      },
    ],
    []
  );

  return (
    <>
      <div>
        <h2>User Table</h2>
        {userTableData && (
          <Table
            data={userTableData}
            columns={columns}
            dataCallback={''}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: true,
              pageSize: 50,
            }}
          />
        )}
      </div>
    </>
  );
}

function DisplayTrialsData() {
  const [trialTableData, setTrialTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/trialsData');
        const result = await response.json();
        setTrialTableData(result);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Num', // Show the row number
        accessor: (_, index) => index + 1, // Calculate row number
        id: 'rowNumber',
      },
      {
        Header: 'Trial ID',
        accessor: 'trial_id',
      },
      {
        Header: 'Trial Name',
        accessor: 'trial_name',
      },
      {
        Header: 'Trial Number',
        accessor: 'trial_number',
      },
      {
        Header: 'Trial Notes',
        accessor: 'trial_notes',
      },
      {
        Header: 'Owner Email',
        accessor: 'owner_email',
      },
      {
        Header: 'Team ID',
        accessor: 'the_team_id',
      },
      {
        Header: 'Team Name',
        accessor: 'team_name',
      },
      {
        Header: 'Creation Date',
        accessor: 'creation_date',
      },
    ],
    []
  );
  return (
    <>
      <div>
        <h2>Trial Table</h2>
        {trialTableData && (
          <Table
            data={trialTableData}
            columns={columns}
            dataCallback={''}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: true,
              pageSize: 50,
            }}
          />
        )}
      </div>
    </>
  );
}

function DisplayParticipantsData() {
  const [participantTableData, setParticipantTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/participantsData');
        const result = await response.json();
        setParticipantTableData(result);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Num', // Show the row number
        accessor: (_, index) => index + 1, // Calculate row number
        id: 'rowNumber',
      },
      {
        Header: 'Participant ID',
        accessor: 'participant_id',
      },
      {
        Header: 'Team ID',
        accessor: 'the_team_id',
      },
      {
        Header: 'Team Name',
        accessor: 'team_name',
      },
      {
        Header: 'Participant Email',
        accessor: 'email',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Last Update',
        accessor: 'last_update',
      },
      {
        Header: 'Creation Time',
        accessor: 'create_time',
      },
    ],
    []
  );

  return (
    <>
      <div>
        <h2>Participant Table</h2>
        {participantTableData && (
          <Table
            data={participantTableData}
            columns={columns}
            dataCallback={''}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: true,
              pageSize: 50,
            }}
          />
        )}
      </div>
    </>
  );
}

function DisplayTeamsData() {
  const [teamTableData, setTeamTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/teamsData');
        const result = await response.json();
        setTeamTableData(result);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Num', // Show the row number
        accessor: (_, index) => index + 1, // Calculate row number
        id: 'rowNumber',
      },
      {
        Header: 'Team ID',
        accessor: 'team_id',
      },
      {
        Header: 'Team Name',
        accessor: 'team_name',
      },
    ],
    []
  );
  return (
    <>
      <div>
        <h2>Team Table</h2>
        {teamTableData && (
          <Table
            data={teamTableData}
            columns={columns}
            dataCallback={''}
            features={{
              singleRowSelection: false,
              multipleRowsSelection: false,
              maxNumOfSelectedRows: null,
              displayGlobalFilter: true,
              pageSize: 50,
            }}
          />
        )}
      </div>
    </>
  );
}

function DbInfoPage() {
  return (
    <>
      <h1>Sys Director Info Page</h1>
      <DisplayUsersData />
      <DisplayTrialsData />
      <DisplayParticipantsData />
      <DisplayTeamsData />
    </>
  );
}

export default DbInfoPage;
